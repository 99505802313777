// App.js
import React, { useState } from 'react';

function App() {
  const [url, setUrl] = useState('');
  const [transcript, setTranscript] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch('/api/format-transcript', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url }),
      });
      const data = await response.json();
      setTranscript(data.transcript);
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);
  };

  return (
    <div className="App">
      <h1>YouTube Transcript Formatter</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter YouTube URL"
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Format Transcript'}
        </button>
      </form>
      {transcript && (
        <div>
          <h2>Formatted Transcript:</h2>
          <pre>{transcript}</pre>
        </div>
      )}
    </div>
  );
}

export default App;